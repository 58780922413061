
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnyObject } from '@/types';
import { VueGoodTable } from 'vue-good-table';
import ListDialog from './overridden-rows/ListDialog.vue';
import TableName from './overridden-rows/TableName.vue';
import TruncatedDisplayValue from './overridden-rows/TruncatedDisplayValue.vue';

import 'vue-good-table/dist/vue-good-table.css';

@Component({
	components: { ListDialog, TableName, TruncatedDisplayValue, VueGoodTable },
})
export default class ParametersTable extends Vue {
	@Prop({ type: String, required: true }) tableTitle!: string;
	@Prop({ type: String, required: true }) description!: string;
	@Prop({ type: Array, required: true }) columns!: { label: string; field: string }[];
	@Prop({ type: Array, required: true }) rows!: AnyObject[];
	@Prop({ type: Boolean, default: true }) filterable!: boolean;
	@Prop({ type: Boolean, default: false }) searchOptionsEnabled!: boolean;

	@Prop(Boolean) lineNumbers?: boolean;
	@Prop(Array) overriddenRows?: AnyObject[];

	overriddenRow(rowName: string) {
		return this.overriddenRows ? this.overriddenRows.find((row) => row.name === rowName) : null;
	}

	get searchOptions() {
		return {
			enabled: this.searchOptionsEnabled,
			placeholder: 'Search for parameters',
		};
	}
}
