var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.hasToBeTruncated
        ? _c(
            "v-dialog",
            {
              attrs: { width: "75%" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _vm._v(" " + _vm._s(_vm.truncatedDisplayValue) + " "),
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  "x-small": "",
                                  color: "secondary",
                                  icon: "",
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", [
                              _vm._v("mdi-dots-horizontal-circle-outline"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2498215511
              ),
              model: {
                value: _vm.isTruncatedDialogVisible,
                callback: function ($$v) {
                  _vm.isTruncatedDialogVisible = $$v
                },
                expression: "isTruncatedDialogVisible",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5 grey lighten-2" },
                    [_vm._v(_vm._s(_vm.parameters[_vm.index].name))]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("vue-json-pretty", {
                        attrs: {
                          data: _vm.displayValue,
                          "show-double-quotes": true,
                          "show-length": true,
                          "show-line": false,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.isTruncatedDialogVisible = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("p", [_vm._v(_vm._s(_vm.displayValue))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }