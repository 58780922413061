
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Note, User } from '@/types';
import { notes as notesModule } from '@/store/modules/easy-firestore/notes';
import { oneDayLimit } from '@/util/dates';
import AvatarComponent from '@/components/common/AvatarComponent.vue';
import NoteEditor from './editor/NoteEditor.vue';

@Component({
	components: { AvatarComponent, NoteEditor },
})
export default class NoteItem extends Vue {
	@Prop({ type: Object, required: true }) note!: Note;
	@Prop(String) moduleName?: string;
	@Prop(String) relatedDocId?: string;
	@Prop(Boolean) isThreadNote?: boolean;
	@Prop(Boolean) readOnly?: boolean;

	@State((state) => state.notes.parentNote) parentNote!: Note;
	@State((state) => state.notes.threadNotes) threadNotes!: Note[];
	@State((state) => state.notes.showThreadPanel) showThreadPanel!: boolean;

	@Getter('user/user') user!: User;

	isHovering: boolean = false;
	isEditorLoading: boolean = false;
	isEditing: boolean = false;
	isDeleting: boolean = false;

	toggleIsEditing() {
		this.isEditing = !this.isEditing;
	}

	openThread() {
		this.$emit('openThread');
	}

	editNote(text: string) {
		this.isEditorLoading = true;
		this.$store
			.dispatch(`${notesModule.moduleName}/patch`, {
				id: this.note.id,
				text,
			})
			.then(() => {
				this.isEditorLoading = false;
				this.toggleIsEditing();
			})
			.catch((err) => {
				console.error(err);
				this.isEditorLoading = false;
			});
	}

	deleteNote() {
		this.isDeleting = true;

		if (this.threadNotes.length === 0 && this.showThreadPanel && this.parentNote.id === this.note.id)
			this.$store.commit('notes/CLOSE_THREAD_PANEL');

		this.$store
			.dispatch(`${notesModule.moduleName}/delete`, this.note.id)
			.then(() => {
				this.isDeleting = false;
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getFormattedTimestamp(timestamp: string) {
		return oneDayLimit(timestamp);
	}

	get isFocused(): boolean {
		return !this.readOnly && (this.isEditing || this.isHovering);
	}

	get showActions(): boolean {
		return !this.readOnly && this.isFocused && this.user.uid === this.note.created_by;
	}

	get showThreadAction(): boolean {
		return !this.isThreadNote && this.isFocused;
	}

	get isParentNote(): boolean {
		return this.note.id === this.parentNote.id;
	}
}
