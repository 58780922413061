
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import NotesRoot from './root/NotesRoot.vue';
import NoteThread from './thread/NoteThread.vue';

interface DefaultProps {
	moduleName: string;
	relatedDocId: string;
	account: string;
}

@Component({
	components: { NotesRoot, NoteThread },
})
export default class NotesTab extends Vue {
	@Prop({ type: String, required: true }) account!: string;
	@Prop({ type: String, required: true }) moduleName!: string;
	@Prop({ type: String, required: true }) relatedDocId!: string;

	@State((state) => state.notes.showThreadPanel) showThreadPanel!: boolean;

	get defaultProps(): DefaultProps {
		return {
			moduleName: this.moduleName,
			relatedDocId: this.relatedDocId,
			account: this.account,
		};
	}
}
