
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueJsonPretty from 'vue-json-pretty';

import 'vue-json-pretty/lib/styles.css';

@Component({
	components: { VueJsonPretty },
})
export default class TruncatedDisplayValue extends Vue {
	@Prop({ type: [String, Object], required: true }) displayValue!: string | object;
	@Prop({ type: Number, required: true }) index!: number;
	@Prop({ type: Array, required: true }) parameters!: object[];

	isTruncatedDialogVisible = false;

	get formattedDisplayValue() {
		return typeof this.displayValue === 'string' ? this.displayValue : JSON.stringify(this.displayValue);
	}

	get hasToBeTruncated() {
		return this.formattedDisplayValue.length > 50;
	}

	get truncatedDisplayValue() {
		return `${this.formattedDisplayValue.substr(0, 50)}...`;
	}
}
