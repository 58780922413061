var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      attrs: { fluid: "" },
      on: {
        keydown: function ($event) {
          return _vm.handleCmdEnter($event)
        },
      },
    },
    [
      _c("tiptap-vuetify", {
        staticClass: "editor my-4",
        attrs: { extensions: _vm.extensions },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      _c(
        "v-btn",
        {
          staticClass: "float-right",
          attrs: {
            loading: _vm.isLoading,
            disabled: _vm.isSaveButtonDisabled,
            color: "primary",
          },
          on: { click: _vm.onValidated },
        },
        [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }