
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TruncatedDisplayValue extends Vue {
	@Prop({ type: Array, required: true }) buttonLabel!: string;
	@Prop({ type: Array, required: true }) list!: string[];
	@Prop({ type: Number, required: true }) index!: number;

	isListDialogVisible = false;
}
