var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("parameters-table", {
    attrs: {
      tableTitle: "Table Schema",
      description: "Schema details of the table",
      columns: _vm.schemaColumns,
      rows: _vm.schemaRows,
      vflexLength: "xs12",
      lineNumbers: false,
      searchOptionsEnabled: true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }