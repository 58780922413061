var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "notes-container" },
    [
      _c(
        "div",
        { ref: "notesThread", staticClass: "notes-wrapper" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "mb-4 thread-toolbar",
              attrs: { dense: "", flat: "", absolute: "" },
            },
            [
              _c("v-toolbar-title", [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Thread"),
                ]),
                _vm._v(" - " + _vm._s(_vm.parentNote.userDisplayName) + " "),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.closeThread } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pt-10" },
            [
              _c("note-item", {
                attrs: {
                  note: _vm.parentNote,
                  "module-name": _vm.moduleName,
                  "related-doc-id": _vm.relatedDocId,
                  "is-thread-note": "",
                },
              }),
              _vm.threadNotes.length > 0
                ? [
                    _c("v-container", [
                      _c("p", { staticClass: "counter font-weight-bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.threadNotes.length) +
                            " " +
                            _vm._s(
                              _vm.threadNotes.length === 1
                                ? "answer"
                                : "answers"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _vm._l(_vm.threadNotes, function (note) {
                      return _c("note-item", {
                        key: note.id,
                        attrs: {
                          note: note,
                          "module-name": _vm.moduleName,
                          "related-doc-id": _vm.relatedDocId,
                          "is-thread-note": "",
                        },
                      })
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("note-editor", {
        attrs: { isLoading: _vm.isEditorLoading },
        on: { onValidated: _vm.onValidated },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }