var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "note-item transition-ease-in-out",
      class: { "grey lighten-3": _vm.isFocused || _vm.isParentNote },
      attrs: { fluid: "" },
      on: {
        mouseenter: function ($event) {
          _vm.isHovering = true
        },
        mouseleave: function ($event) {
          _vm.isHovering = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("avatar-component", {
            staticClass: "mr-2",
            attrs: {
              user: {
                email: _vm.note.userEmail,
                photoURL: _vm.note.userPhotoURL,
              },
            },
          }),
          _c("span", { staticClass: "mr-2 font-weight-bold" }, [
            _vm._v(_vm._s(_vm.note.userDisplayName)),
          ]),
          _c("span", { staticClass: "mr-2" }, [
            _vm._v(_vm._s(_vm.getFormattedTimestamp(_vm.note.created_at))),
          ]),
          _vm.note.updated_at
            ? _c("span", { staticClass: "mr-2" }, [
                _vm._v(
                  "(edited " +
                    _vm._s(_vm.getFormattedTimestamp(_vm.note.updated_at)) +
                    ")"
                ),
              ])
            : _vm._e(),
          _c("v-spacer"),
          _vm.showActions
            ? _c(
                "div",
                { staticClass: "actions pa-2 grey lighten-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { "x-small": "" },
                      on: { click: _vm.toggleIsEditing },
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("mdi-pencil"),
                      ]),
                    ],
                    1
                  ),
                  _vm.showThreadAction
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { "x-small": "" },
                          on: { click: _vm.openThread },
                        },
                        [
                          _c("v-icon", { attrs: { "x-small": "" } }, [
                            _vm._v("mdi-message-text"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.isDeleting,
                        "x-small": "",
                        color: "error",
                      },
                      on: { click: _vm.deleteNote },
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("mdi-delete"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isEditing
        ? _c("note-editor", {
            attrs: {
              "module-name": _vm.moduleName,
              "related-doc-id": _vm.relatedDocId,
              account: _vm.note.account,
              "default-text": _vm.note.text,
              "is-editing": _vm.isEditing,
              "is-loading": _vm.isEditorLoading,
            },
            on: { onValidated: _vm.editNote },
          })
        : _c("div", {
            staticClass: "ml-11 text",
            domProps: { innerHTML: _vm._s(_vm.note.text) },
          }),
      _vm._t("thread-information"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }