var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "notes-container" },
    [
      _vm.isLoading
        ? _c("v-progress-circular", {
            staticClass: "d-flex mx-auto my-0",
            attrs: { indeterminate: "", size: "42", color: "primary" },
          })
        : Object.keys(_vm.parentNotes).length > 0
        ? _c(
            "div",
            { ref: "notesRoot", staticClass: "notes-wrapper" },
            _vm._l(_vm.parentNotes, function (parentNote) {
              return _c("note-item", {
                key: parentNote.id,
                attrs: {
                  note: parentNote,
                  "module-name": _vm.moduleName,
                  "related-doc-id": _vm.relatedDocId,
                },
                on: {
                  openThread: function ($event) {
                    return _vm.openThread(parentNote)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "thread-information",
                      fn: function () {
                        return [
                          _vm.getThreadNotes(parentNote.id).length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center ml-8 mt-2 px-2 py-3 thread-information cursor--pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openThread(parentNote)
                                    },
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.getInvolvedUsers(parentNote),
                                    function (note, index) {
                                      return _c("avatar-component", {
                                        key: `${note.id}-${index}`,
                                        staticClass: "mr-1",
                                        attrs: {
                                          user: {
                                            email: note.userEmail,
                                            photoURL: note.userPhotoURL,
                                          },
                                          size: "x-small",
                                        },
                                      })
                                    }
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "ml-2 font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getThreadNotes(parentNote.id)
                                                .length
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.getThreadNotes(parentNote.id)
                                                .length === 1
                                                ? "answer"
                                                : "answers"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("span", { staticClass: "font-italic" }, [
                                      _vm._v(
                                        " Last answer: " +
                                          _vm._s(
                                            _vm.getFormatedTimestamp(
                                              _vm.getThreadNotes(parentNote.id)
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          )
        : _c("v-container", { staticClass: "ml-1 mt-3" }, [
            _c("p", [_vm._v("Any note for the moment.")]),
          ]),
      _c("note-editor", {
        attrs: { isLoading: _vm.isEditorLoading },
        on: { onValidated: _vm.onValidated },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }