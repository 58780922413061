var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "pl-5 pt-4 pr-5" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", offset: "0" } },
            [
              _vm.jsonId
                ? _c("span", { staticClass: "text-h5 font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.jsonId)),
                  ])
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "pt-4 pb-4" },
                [
                  _c("vue-json-pretty", {
                    staticClass: "json-pretty",
                    attrs: {
                      data: _vm.json,
                      deep: _vm.deep,
                      "show-double-quotes": true,
                      "show-length": true,
                      "show-line": false,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }