var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pt-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  class: { "bordered-col": _vm.showThreadPanel },
                  attrs: { cols: _vm.showThreadPanel ? 7 : 12 },
                },
                [
                  _c(
                    "notes-root",
                    _vm._b({}, "notes-root", _vm.defaultProps, false)
                  ),
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.showThreadPanel
                    ? _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "5" } },
                        [
                          _c(
                            "note-thread",
                            _vm._b({}, "note-thread", _vm.defaultProps, false)
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }