
import { Component, Mixins } from 'vue-property-decorator';
import { Note } from '@/types';
import { State } from 'vuex-class';
import { notes as notesModule } from '@/store/modules/easy-firestore/notes';
import { oneDayLimit } from '@/util/dates';

import NotesMixin from '../notes-mixin';
import AvatarComponent from '@/components/common/AvatarComponent.vue';

@Component({
	components: { AvatarComponent },
})
export default class NotesRoot extends Mixins(NotesMixin) {
	@State((state) => state.notes.data) notes!: Note[];
	@State((state) => state.notes.parentNote) parentNote!: Note;

	isLoading: boolean = true;

	mounted() {
		this.fetchNotes();
	}

	activated() {
		this.fetchNotes();
	}

	fetchNotes() {
		if (!this.where) throw new Error('You must provide a where getter.');

		this.$store.dispatch(`${notesModule.moduleName}/closeDBChannel`, { clearModule: true });
		this.$store.dispatch(`${notesModule.moduleName}/openDBChannel`, { where: this.where });
		this.$store.dispatch(`${notesModule.moduleName}/fetchAndAdd`, { where: this.where }).then(() => {
			this.isLoading = false;
		});
	}

	openThread(parentNote: Note) {
		this.parentNote.id === parentNote.id
			? this.$store.commit('notes/CLOSE_THREAD_PANEL')
			: this.$store.commit('notes/OPEN_THREAD_PANEL', { parentNote, threadNotes: this.getThreadNotes(parentNote.id) });
	}

	getThreadNotes(parentNoteId: string) {
		return Object.values(this.notes).filter((note: Note) => note.parentNoteId === parentNoteId);
	}

	getInvolvedUsers(parentNote: Note) {
		const users = this.getThreadNotes(parentNote.id);

		return users.filter((obj, pos, arr) => {
			return arr.map((mapObj) => mapObj.userId).indexOf(obj.userId) === pos;
		});
	}

	getFormatedTimestamp(threadNotes: Note[]) {
		const timestamp = threadNotes[threadNotes.length - 1].updated_at || threadNotes[threadNotes.length - 1].created_at;
		return oneDayLimit(timestamp);
	}

	onValidated(text: string) {
		this.insertNote(text, this.$refs.notesRoot);
	}

	get where() {
		return [
			['account', '==', JSON.parse(localStorage.vuex).filters.filteredAccounts[0].id],
			['moduleName', '==', this.moduleName],
			['relatedDocId', '==', this.relatedDocId],
		];
	}

	get parentNotes() {
		return Object.values(this.notes).filter((note: Note) => !note.isThreadNote);
	}

	get insertData() {
		return {
			account: this.account,
			isThreadNote: false,
			moduleName: this.moduleName,
			relatedDocId: this.relatedDocId,
			routeName: this.$route.name,
			userId: this.user.uid,
			userEmail: this.user.email,
			userPhotoURL: this.user.photoURL,
			userDisplayName: this.user.displayName,
		};
	}
}
