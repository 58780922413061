import { render, staticRenderFns } from "./NotesRoot.vue?vue&type=template&id=6cf81868&"
import script from "./NotesRoot.vue?vue&type=script&lang=ts&"
export * from "./NotesRoot.vue?vue&type=script&lang=ts&"
import style0 from "./NotesRoot.vue?vue&type=style&index=0&id=6cf81868&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tailer-studio/tailer-studio/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6cf81868')) {
      api.createRecord('6cf81868', component.options)
    } else {
      api.reload('6cf81868', component.options)
    }
    module.hot.accept("./NotesRoot.vue?vue&type=template&id=6cf81868&", function () {
      api.rerender('6cf81868', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/data-operations/common/item/notes/root/NotesRoot.vue"
export default component.exports