var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-col",
        [
          _c(
            "h2",
            { staticClass: "black--text pb-3" },
            [
              _vm._v(" " + _vm._s(_vm.tableTitle) + " "),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              {
                                attrs: {
                                  color: "blue-grey lighten-5",
                                  dark: "",
                                },
                              },
                              on
                            ),
                            [_vm._v("info")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v(_vm._s(_vm.description))])]
              ),
            ],
            1
          ),
          _c(
            "vue-good-table",
            {
              staticClass: "word-break",
              attrs: {
                "fixed-header": "",
                "max-height": "75vh",
                columns: _vm.columns,
                rows: _vm.rows,
                filterable: _vm.filterable,
                "search-options": _vm.searchOptions,
                styleClass: "vgt-table striped condensed",
                "line-numbers": _vm.lineNumbers,
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function (props) {
                    return [
                      _vm.overriddenRow(props.column.field) &&
                      _vm.overriddenRow(props.column.field).name ===
                        props.column.field
                        ? _c(
                            _vm.overriddenRow(props.column.field).component,
                            _vm._b(
                              { tag: "component" },
                              "component",
                              {
                                ..._vm.overriddenRow(props.column.field).props,
                                index: props.index,
                                displayValue:
                                  props.formattedRow[props.column.field],
                              },
                              false
                            )
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("div", { attrs: { slot: "emptystate" }, slot: "emptystate" }, [
                _vm._v("No information to display"),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }