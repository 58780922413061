
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AnyObject } from '@/types';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

@Component({
	components: { VueJsonPretty },
})
export default class ViewJSON extends Vue {
	@Prop({ type: Object, required: true }) json!: AnyObject;
	@Prop({ type: Number, default: 5 }) deep!: number;
	@Prop(String) jsonId?: string;
}
