
import { Component, Mixins } from 'vue-property-decorator';
import { Note } from '@/types';
import { State } from 'vuex-class';

import NotesMixin from '../notes-mixin';

@Component
export default class NoteThread extends Mixins(NotesMixin) {
	@State((state) => state.notes.parentNote) parentNote!: Note;
	@State((state) => state.notes.threadNotes) threadNotes!: Note[];

	closeThread() {
		this.$store.commit('notes/CLOSE_THREAD_PANEL');
	}

	onValidated(text: string) {
		this.insertNote(text, this.$refs.notesThread);
	}

	get insertData() {
		return {
			account: this.account,
			isThreadNote: true,
			moduleName: this.moduleName,
			relatedDocId: this.relatedDocId,
			routeName: this.$route.name,
			parentNoteId: this.parentNote.id,
			userId: this.user.uid,
			userEmail: this.user.email,
			userPhotoURL: this.user.photoURL,
			userDisplayName: this.user.displayName,
		};
	}
}
