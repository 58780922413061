var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "420" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "p",
                _vm._g(
                  _vm._b(
                    { staticClass: "text-decoration-underline" },
                    "p",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.buttonLabel[_vm.index]))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isListDialogVisible,
        callback: function ($$v) {
          _vm.isListDialogVisible = $$v
        },
        expression: "isListDialogVisible",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { tile: "" } },
        [
          _c("v-card-title", [_vm._v("Filters")]),
          _vm._l(_vm.list[_vm.index], function (listItem) {
            return _c(
              "v-list-item",
              { key: listItem, staticClass: "px-6" },
              [
                _c(
                  "v-list-item-content",
                  [_c("v-list-item-title", [_vm._v(_vm._s(listItem))])],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }