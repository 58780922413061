var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "span",
        {
          staticClass: "table-name light-blue--text",
          on: {
            click: function ($event) {
              _vm.showDetailsDialog = true
            },
          },
        },
        [_vm._v(_vm._s(_vm.displayValue))]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "65%" },
          model: {
            value: _vm.showDetailsDialog,
            callback: function ($$v) {
              _vm.showDetailsDialog = $$v
            },
            expression: "showDetailsDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { "min-height": "90vh" } },
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5" },
                [
                  _c("span", [_vm._v("Details")]),
                  _c("v-spacer"),
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showDetailsDialog = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "elevation-1",
                      attrs: {
                        color: "black",
                        "background-color": "#E0E0E0",
                        "slider-color": "primary",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _vm._l(_vm.tabs, function (tab) {
                        return _c(
                          "v-tab",
                          { key: tab.href, attrs: { href: `#${tab.href}` } },
                          [_vm._v(_vm._s(tab.label))]
                        )
                      }),
                      _c(
                        "v-tab-item",
                        { attrs: { value: _vm.getTabHref(0) } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "", tile: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("vue-good-table", {
                                    attrs: {
                                      columns: _vm.schemaColumns,
                                      rows: _vm.schema,
                                      styleClass: "vgt-table striped condensed",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: _vm.getTabHref(1) } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "", tile: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("parameters-list", {
                                    attrs: {
                                      groupTitle: "Table parameters",
                                      description:
                                        "All parameters used with this table",
                                      paramItems: _vm.parametersJson,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: _vm.getTabHref(2) } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "", tile: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("vue-markdown", {
                                    attrs: { source: _vm.docMdDecoded },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }